@import "../../utils/_variables.scss";

.CreateGreeting {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }

  .CreateGreetingSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex: 1;

 

    .VoiceCardImage {
      width: 320px;
      height: 320px;
    }
  }

  .GreetingInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    @media only screen and (max-width: 1100px) {
      margin-top: 30px;
    }
    .GeneralHowTo {
        display: flex;
        flex-direction: column;
        gap: 30px;
      font-size: 20px;
      color: $base-theme-medium-blue;
      margin-bottom: 30px;
      width: 70%;

      @media only screen and (max-width: 1100px) {
        width: 100%;
      }
    }

    .GreetingForm {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
    }
  }
}


