@import "../../utils/_variables.scss";

.VoiceCardImage {
    padding: 8px;
    width: 220px;
    height: 220px;
    object-fit: cover;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, rgb(147, 68, 221), $base-theme-orange) border-box;
    border-radius: 50em;
    border: 4px solid transparent;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.1)
    }
} 