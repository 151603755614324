@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

main {
  min-height: calc(100vh - 220px);
}

.Page, 
.BackButton {
  margin: 50px;
  margin-bottom: 20px;
  height: 100%;
  animation: fade-in 1s;
}
